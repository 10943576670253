import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import parse from 'html-react-parser'


const BlogPost = ({data}) => {
    return (
        <Layout data={data.wpPost} >
          <main className="blogPost">
              <section className="py-20">
                <div className="container mx-auto">
                    <div className="w-3/4 lg:w-2/3 mx-auto">{parse(data.wpPost.content)}</div>
                </div>
              </section>
          </main>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String) {
        wpPost(slug: {eq: $slug}) {
            content
            slug
            title
            nodeType
            defaults {
                subtitle
            }
            featuredImage {
              node {
                gatsbyImage(width: 1000)
                localFile {
                  childImageSharp{
                    gatsbyImageData(width:200)
                  }
                }
              }
            }
            seo {
              metaDesc
              opengraphUrl
              opengraphType
              opengraphTitle
              opengraphSiteName
              title
            }
        }
    }
`

export default BlogPost